import { Box } from '@mui/material';
import Error from './error';
import Header from '../../components/header';

function NotFound() {
  return (
    <Box>
      <Header/>
      <Error/>
    </Box>
  );
}

export default NotFound;