import { ThemeProvider, createTheme } from '@mui/material/styles';
import MailChimpForm from '../helpers/mailchimpSubscribe';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';


function SignUpForm() {
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    }
  });

  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  
  return (
    <Container
      sx={{
        mt: 8,
        mb: {md: 7.5, xs: 6.25},
        mx: 'auto',
        px: '0 !important',
        maxWidth: {xs: 327, sm: 552, md: 880}
      }}
      theme={breakpoints}
    >
      <Grid container>
        <Grid item xs={12}>
          <ThemeProvider theme={faustina}>
            <Typography sx={{
              color: "#AE1B29",
              textAlign: "left",
              fontSize: {md: 36, xs: 28},
              lineHeight: {md: 1.277, xs: 1.214}
            }}
            >
              Stay involved
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={barlow}>
            <Typography 
              color="#2C2E31" 
              textAlign="left" 
              fontWeight={400} 
              fontSize={18} 
              lineHeight={1.5} 
              sx={{
                mt: {xs: 2},
                mb: {md: 5, sm: 6.125, xs: 2},
                // width: {xs: 327, sm: 552},
              }}>
              Not ready to participate at this time? Sign up to be the first to know about any future events from Marqued.
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12}>
          <MailChimpForm />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SignUpForm;