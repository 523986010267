import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import EditorialHero from "./hero";
import EditorialStory from "./story";


function EditorialComponent(){
  return (
    <Container>
      <Box
        sx={{
          mx: 'auto',
          px: '0 !important',
          position: 'relative',
          zIndex: 100,
        }}
      >
        <EditorialHero />
      </Box>
      <Box
        sx={{
          mx: 'auto',
          px: '0 !important',
          position: 'relative',
          zIndex: 100,
          mt: -10.625,
        }}
      >
        <EditorialStory />
      </Box>
    </Container>
  );
}

export default EditorialComponent;