import { ThemeProvider, createTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Container, Typography } from '@mui/material';
import Images from '../assets/images';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

function TrackDay() {
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
  });

  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Container
      sx={{
        mb: {md: 8, xs: 6.25},
        mx: 'auto',
        px: '0 !important',
        maxWidth: {xs: 327, sm: 552, md: 880}
      }}
      theme={breakpoints}
    >
      <ThemeProvider theme={faustina}>
        <Typography sx={{
          color: "#AE1B29",
          textAlign: "left",
          fontSize: {md: 36, xs: 28},
          lineHeight: {md: 1.277, xs: 1.214},
          mb: 4
        }}>
          Need more information?
        </Typography>
      </ThemeProvider>
      <Grid container
        sx={{
          textDecoration: 'none',
          '&:hover': {
            boxShadow: '0px 8px 32px rgb(0 0 0 / 20%)'
          },
        }}
        component={Link} to='editorial'
      >
        <Grid item sm={4} xs={12}>
          <Box
            sx={{
              position: 'relative',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: Images.editorialThumbnailNew,
              height: {xs: 192, md: 214},
            }}
            theme={breakpoints}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Box
            sx={{
              background: '#F7F7F7',
              height: {sm: 192, md: 214},
              px: {xs: 2, sm: 3, md: 5},
              py: 3,
            }}
            theme={breakpoints}
          >
            <ThemeProvider theme={barlow}>
              <Typography 
                sx={{
                  color: "#2C2E31", 
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: {xs: 22, md: 32},
                  lineHeight: {xs: 1.10, md: 1.1875},
                }}>
                Why the track is right for you
              </Typography>
              <Typography 
                sx={{
                  color: "#2C2E31", 
                  textAlign: "left",
                  fontWeight: 400,
                  fontSize: 18,
                  lineHeight: {xs: 1.222, md: 1.333},
                  my: 2,
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}>
                If you thought that driving on race tracks was limited to racing drivers, seasoned enthusiasts and motoring journalists you’d be wrong—anyone can do it….
              </Typography>
              <Typography 
                sx={{
                  color: "#2C2E31", 
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: 18,
                  lineHeight: {sm: 1.222, md: 1.333},
                }}>
                4 min read
              </Typography>
            </ThemeProvider>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TrackDay;