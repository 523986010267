import Box from '@mui/material/Box';
import Images from '../assets/images';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import Header from './header';


function Hero() {
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1024,
        xl: 1440
      },
    },
  });

  const logoBreakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1024,
        xl: 1440
      },
    },
  });

  return (
    <Box theme={logoBreakpoints} sx={{
      width: '100vw',
      ml: '-50vw',
      position: 'relative',
      left: '50%',
      backgroundColor: '#F2F2F2',
    }}>
      <Container maxWidth='lg' disableGutters>
        <ThemeProvider theme={faustina}>
          <Header/>
          <Box 
            sx={{
              position: 'relative',
              backgroundColor: '#18191A',
              color: '#fff',
              mx: 'auto',
              mb: {xs: 6, sm: 8},
              height: {md: 400, sm: 320, xs: 260},
              // maxWidth: {xl: 1200, md: 880, xs: 600},
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: Images.heroDesktopNew,
            }}
          />
        </ThemeProvider>
      </Container>
    </Box>
  );
}

export default Hero;