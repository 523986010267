import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Images from '../assets/images';
import Box from '@mui/material/Box';
import {ReactComponent as DateSVG} from '../public/date.svg';
import {ReactComponent as LocSVG} from '../public/loc.svg';
import {ReactComponent as PriceSVG} from '../public/price.svg';
import ReactGA from 'react-ga4';

function UpComing() {
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  function trackEvent(){
    ReactGA.event({
      category: "click",
      action: "bookTicket",
    });
  }

  return (
    <Box sx={{
      mb: {md: 8, xs: 6.25},
      width: '100vw',
      ml: '-50vw',
      position: 'relative',
      left: '50%',
      backgroundColor: '#18191A',
    }}>
      <Container sx={{
        pt: {
          xs: 6,
          sm: 8
        },
        pb: {
          xs: 8,
          sm: 10
        },
        mx: 'auto',
        px: '0 !important',
        maxWidth: {xs: 327, sm: 552, md: 880}
      }}
      theme={breakpoints}>
        <ThemeProvider theme={faustina}>
          <Typography sx={{
            color: "#FFFFFF",
            textAlign: "left",
            fontSize: {md: 36, xs: 28},
            lineHeight: {md: 1.277, xs: 1.214},
            // mb: {sm: 6, xs: 5}
          }}>
            High Performance Drivers Education at Thunderhill 
          </Typography>
        </ThemeProvider>
        <ThemeProvider theme={barlow}>
          <Typography color="#ffffff" textAlign="left" fontWeight={400} lineHeight={1.5} fontSize={18} sx={{
          }}>
            <br />
            This program is specifically designed to teach you performance driving in a safe, controlled environment. Just show up with <b>your car in good condition</b> and our expert team will take care of the rest. <b>Any type of car</b> from a Honda Civic to a Porsche Cayenne will work. Most daily drivers perform perfectly well at our events, no modifications or upgrades are necessary for this event.<br /><br />

            No prior experience is required. Our team of expert coaches will be by your side all day, engaging in <b>classroom learning sessions, car-control exercises,</b> and <b>personalized feedback</b> on the track.<br /><br />  
            
            Click the BOOK TICKETS button to register via our partner – <b>Hooked on Driving.</b>  
            <br />
          </Typography>
          <Typography color="#ffffff" textAlign="left" fontWeight={600} variant="h5" sx={{
            mb: 2,
            mt: {sm: 4.125, xs: 5}
          }}>
            Event Info
          </Typography>
        </ThemeProvider>
        <Grid container columns={44}>
          <Grid item xs={44} sm={14} theme={breakpoints}>
            <Box theme={breakpoints}
              sx={{
                position: 'relative',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: Images.eventThumbnail,
                height: {sm: 288, xs: 218}
              }}
            />
          </Grid>
          <Grid item xs={44} sm={30} theme={breakpoints} backgroundColor='#ffffff' sx={{
            pt: {sm: 3, xs: 2},
            px: {md: 5, sm: 3, xs: 2},
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <DateSVG height={16} width={16}/>
              <ThemeProvider theme={barlow}>
                <Typography color="#2C2E31" textAlign="left" fontWeight={600} fontSize={18} sx={{
                  ml: 1
                }}>
                  March 24
                </Typography>
              </ThemeProvider>
            </Box>
            <Box sx={{
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <LocSVG height={16} width={16}/>
              <ThemeProvider theme={barlow}>
                <Typography color="#2C2E31" textAlign="left" fontWeight={600} fontSize={18} sx={{
                  ml: 1
                }}>
                  Thunderhill Raceway Park
                </Typography>
              </ThemeProvider>
            </Box>
            <Box sx={{
              // mt: {md: 0.5},
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <ThemeProvider theme={barlow}>
                <Typography color="#2C2E31" textAlign="left" fontWeight={400} fontSize={18} sx={{
                  ml: 3,
                }}>
                  5250 HWY-162, Willows, CA 95988
                </Typography>
              </ThemeProvider>
            </Box>
            <Box sx={{
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <PriceSVG height={16} width={16}/>
              <ThemeProvider theme={barlow}>
                <Typography color="#2C2E31" textAlign="left" fontWeight={600} fontSize={18} sx={{
                  ml: 1
                }}>
                  $500
                </Typography>
              </ThemeProvider>
            </Box>
            <Divider sx={{
              mt: {sm: 3, xs: 2},
              borderColor: '#E1E2E3',
              borderWidth: '1px',
            }}/>
            <Button onClick={trackEvent} theme={breakpoints} sx={{  
              background: "#18191A",
              color: "#ffffff",
              boxShadow: 0,
              border: 0,
              p: 0,
              borderRadius: 0,  
              '&:hover': {
                background: '#438ABE',
                boxShadow: 'none',
              },
              width: '100%',
              my: {sm: 3, xs: 2}, 
              maxWidth: {xs: 327, sm: 140, md: 151},
              minWidth: {xs:  0, sm: 140, md: 151},
            }}
            target="_blank" href="https://marqued.hookedondriving.com/index-marqued.cfm"
            >
              <ThemeProvider theme={barlow}>
                <Typography color="#ffffff" textAlign="center" fontWeight={500} fontSize={18} lineHeight={1.22} sx={{
                  py: 1.5,
                  px: 1.5,
                }}>
                BOOK TICKETS
                </Typography>
              </ThemeProvider>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default UpComing;