import { Box, Grid, Link, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function Footer() {
  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  return (
    <Box sx={{
      width: '100vw',
      ml: '-50vw',
      position: 'relative',
      left: '50%',
      backgroundColor: '#18191A',
    }}>
      <ThemeProvider theme={barlow}>
        <Typography color="#ffffff" textAlign="center" fontWeight={400} fontSize={15}>
          <Box sx={{
            height: {md: 178, xs: 200},
            width: {md: 610, sm: 550},
            m: 'auto'
          }}>
            <Grid container alignItems="center" justifyContent="center" theme={breakpoints} sx={{
              py: {sm: 10, xs: 5},
              // mx: 14.75
            }}>
              <Grid item sm={2.8} xs={12} sx={{
                pr: {md: 2, sm: 1},
                borderRight: {sm: '1px solid #ffffff', xs: 0},
                height: 18
              }}>
                <Link sx={{
                  textDecoration: "none",
                  color: '#ffffff',
                }} href="/tos" target="_blank">
                  Terms & Conditions
                </Link>
              </Grid>
              <Grid item sm={2.5} xs={12} sx={{
                px: {md: 2, sm: 1},
                mt: {sm: 0, xs: 2},
                borderRight: {sm: '1px solid #ffffff', xs: 0},
                height: 18 
              }}>
                <Link sx={{
                  textDecoration: "none",
                  color: '#ffffff',
                }} href="/privacy" target="_blank">
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item sm={5.1} xs={12} sx={{
                px: {md: 2, sm: 1},
                mt: {sm: 0, xs: 2},
                borderRight: {sm: '1px solid #ffffff', xs: 0},
                height: 18
              }}>
                <Link sx={{
                  textDecoration: "none",
                  color: '#ffffff',
                  height: 18
                }} href="https://www.porsche.com/usa/privacy-policy/contact/" target="_blank">
                  Do Not Sell My Personal Information
                </Link>
              </Grid>
              <Grid item sm={1.6} xs={12} sx={{
                pl: {md: 2, sm: 1},
                mt: {sm: 0, xs: 2},
                height: 18
              }}>
                <Link sx={{
                  textDecoration: "none",
                  color: '#ffffff',
                  height: 18
                }} href="mailto:support@marquedevents.com">
                  Contact Us
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Typography>
      </ThemeProvider>
    </Box>
  );
}

export default Footer;