import { Box, Container} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';
import Link from '@mui/material/Link';
import { ReactComponent as Logo } from '../public/marquedeventsXhod.svg';

function Header() {
  const logoBreakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1024,
        xl: 1440
      },
    },
  });
  return (
    <Box theme={logoBreakpoints} sx={{
      width: '100vw',
      ml: '-50vw',
      position: 'relative',
      left: '50%',
      backgroundColor: '#FFFFFF',
      height: {md: 64, sm: 49, xs: 40}
    }}>
      <Container theme={logoBreakpoints} sx={{
        // py: {md: 2, xs: 1},
        height: {md: 64, sm: 49, xs: 40},
        width: 315,
      }}>
        <Link target="_blank" href="https://marqued.com/">
          <SvgIcon component={Logo} 
            viewBox='0 0 315 26' 
            theme={logoBreakpoints} 
            sx={{
              width: 315,
              // p: {md: 1, xs: .25},
              height: {md: 64, sm: 49, xs: 40},
            }}
          />
        </Link>
      </Container>
    </Box>
  );
}

export default Header;