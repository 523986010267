import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Typography, Link } from '@mui/material';
import Images from '../../assets/images';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';

function EditorialStory(){
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Box
      sx={{
        mb: {
          xs: 8,
          // sm: 10
        },
        mx: 'auto',
        px: {md: 4, sm: 1.5, xs: 1.375},
        pt: {md: 3, xs: 2},
        maxWidth: {xs: 340, sm: 552, md: 880},
        background: '#FFFFFF'
      }}
      theme={breakpoints}
    >
      <ThemeProvider theme={faustina}>
        <Typography variant="h4" 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontSize: {md: 48, sm: 36, xs: 28},
            lineHeight: {md: 1.25, sm: 1.11, xs: 1.214}
          }}
        >
          Why the track is right for you: What beginners need to know about trackdays
        </Typography>
      </ThemeProvider>
      <ThemeProvider theme={barlow}>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={15} 
          lineHeight={1.2} 
          sx={{
            mt: {xs: 1, sm: 2},
            mb: {xs: 6, md: 8}
          }}>
            Jan 15, 2022
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          So, you’re on deck for your first track-day. Clipping apexes on sweeping stretches of asphalt where speed limits don’t apply. As much as we enjoy an early morning blast through a canyon or up a winding country road, we can never fully explore our driving potential while on the road. The race track is the only place you can truly push the limits of your driving legally and with a degree of safety.<br /><br />
          
          If you thought that driving on race tracks was limited to racing drivers, seasoned enthusiasts and motoring journalists you’d be wrong—anyone can do it. Thanks to the increasing popularity of track-days the opportunities have never been better. For those who have considered booking a track-day but weren’t sure where to start and hopefully for a few of you who have never considered a track-day at all, here are some introductory tips to get you started
        </Typography>
        <Box
          sx={{
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: Images.editorialTicket,
            my: 3,
            height: {xs: 219, sm: 369, md: 588},
          }}
          theme={breakpoints}
        />
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          We’ve enlisted the expertise of Daniel Lackey, race car specialist and team manager in the Porsche Carrera Cup to help get you started. Here’s his list of advice, first things first:
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: 24,
            lineHeight: 1.25,
            mt: 6,
            mb: 3
          }} 
        >
          You don’t need a performance sports car
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          Within reason. If you are taking your own car on a track-day it must be safe and in good working order—more on that later. You do not need a performance sports car or a special build track-day machine. Don’t feel you need to wait for that BMW M3 or Porsche 911 before you can enjoy driving on track. You don’t! 
        </Typography>
        <Box
          sx={{
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: Images.editorialMercedes,
            my: 3,
            height: {xs: 219, sm: 369, md: 588},
          }}
          theme={breakpoints}
        />
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          If you have never driven on track then chances are your generic grocery getter will be far more capable than you as a driver. It will provide a safe platform from which you can start to build your techniques and you’ll be surprised how much fun it can provide. Only as you develop your skills will the need for greater performance become apparent.
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: 24,
            lineHeight: 1.25,
            mt: 6,
            mb: 3
          }} 
        >
          It’s not just about driving fast
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          Don’t be intimidated. There are many great reasons for getting out on track but it’s not just about driving as fast as you can. For as many people who are excited by the prospect of driving at speed, there are an equal number who will be daunted by it. There is a lot more to performance driving than speed alone and you should never feel pressured to drive faster than you are comfortable with. Performance driving is a discipline much like any other sport and you must develop the necessary techniques before increasing your pace.
        </Typography>
        <Box
          sx={{
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: Images.editorialBMW,
            my: 3,
            height: {xs: 219, sm: 369, md: 588},
          }}
          theme={breakpoints}
        />
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: 24,
            lineHeight: 1.25,
            mt: 6,
            mb: 3
          }} 
        >
          Learning your car’s limits
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          Participating in track-days is an excellent way to build your confidence behind the wheel. Most novice drivers have very little understanding of what their car is actually capable of, how much grip they really have and how their car will respond closer to its limits. Taking the same car you drive on the street, pushing it through a turn at 80 or 90 MPH and seeing how stable it remains can be a real eye opener. The typical response is, ‘I had know idea my car was capable of that!’ Connecting with your car and learning to feel its connection to the road is an invaluable experience.
        </Typography>
        <Box
          sx={{
            position: 'relative',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: Images.editorialPirelli,
            my: 3,
            height: {xs: 219, sm: 369, md: 588},
          }}
          theme={breakpoints}
        />
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: 24,
            lineHeight: 1.25,
            mt: 6,
            mb: 3
          }} 
        >
          Meet your fellow drivers & have fun
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          The race track is a great place to meet like-minded people and share your experiences. There can be great camaraderie between drivers and it’s a great way to pick up tips and lessons learned in everything from driving to car setup. Ultimately, you’re all there for the same reason—to learn and have fun. Might as well do it together!
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: 24,
            lineHeight: 1.25,
            mt: 6,
            mb: 3
          }} 
        >
          Questions?
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.33} 
        >
          Have more questions that were not answered? Need more information? Please contact us at <Link href='mailto:concierge@MarquedEvents.com' color="inherit">concierge@MarquedEvents.com</Link>. We are here to help. 
        </Typography>
        <Divider variant="middle"
          sx={{
            my: {xs: 6,md: 8}
          }}
        />
        <Box sx={{
          textAlign: 'center',
        }}> 
          <Button 
            variant="outlined"
            sx={{  
              boxShadow: 0,
              border: '1px solid #2C2E31',
              p: 0,
              height: 48,
              width: {xs: '100%'},
              maxWidth: {xs: 327, sm: 203},
              borderRadius: 0,  
              '&:hover': {
                border: '1px solid #438ABE',
                backgroundColor: '#00000000',
                boxShadow: 'none',
              },
              'a': {
                textDecoration: 'inherit'
              }
            }}
            href='/'
          >
            <Typography color="#2C2E31" textAlign="center" component='span' fontWeight={500} fontSize={18} sx={{
              py: 1.5,
              px: 3,
              '&:hover': {
                color: '#438ABE'
              },
              'a': {
                textDecoration: 'inherit'
              }
            }}>
                BACK TO EVENT PAGE
            </Typography>
          </Button>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default EditorialStory;