import { Box } from '@mui/material';
// import Error from './error';
import TosHeader from './TosHeader';
import Header from '../../components/header';
import TosBody from './body';

function TosComponent(){
  return (
    <Box>
      <Header/>
      <TosHeader/>
      <TosBody />
    </Box>
  );
}

export default TosComponent;