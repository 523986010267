import { Container, Grid, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {ReactComponent as SafeSVG} from '../public/safe.svg';
import {ReactComponent as FunSVG} from '../public/fun.svg';
import {ReactComponent as SocialSVG} from '../public/social.svg';

function AboutUs() {
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
  });

  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Container
      sx={{
        mb: {
          xs: 8,
          sm: 10
        },
        mx: 'auto',
        px: '0 !important',
        maxWidth: {xs: 327, sm: 552, md: 880}
      }}
      theme={breakpoints}
    >
      <ThemeProvider theme={faustina}>
        <Typography sx={{
          color: "#AE1B29",
          textAlign: "left",
          fontSize: {md: 36, xs: 28},
          lineHeight: {md: 1.277, xs: 1.214}
        }}>
          Get started with the right event
        </Typography>
      </ThemeProvider>
      <ThemeProvider theme={barlow}>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          lineHeight={1.5} 
          sx={{
            mt: 3,
            mb: 6
          }}>
          Marqued, the community for automotive enthusiasts is partnering with HookedOnDriving to pilot our first performance driving event. Join us for the exclusive track day event designed for beginners. <br /> <br />

          Explore the capabilities of your vehicle while learning to drive on track in a relaxed, safe atmosphere. Expert coaching, increased time on track, and an all beginner atmosphere make this event the perfect opportunity to get started with performance driving.
        </Typography>
      </ThemeProvider>
      <Grid container alignItems="flex-end" theme={breakpoints} sx={{
        mt: {md: 10},
        height: {md: 94, sm: 106},
        px: {sm: 0, xs: 4.25}
      }}>
        <Grid item theme={breakpoints} md={4} sm={3.5} xs={12} sx={{
          borderRight: {sm: '1px solid #E1E2E3', xs: 0},
          mb: {sm: 0, xs: 5},
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            pr: {md: 5, sm: 4, xs: 0},
          }}>
            <SvgIcon>
              <SafeSVG />
            </SvgIcon>
            <ThemeProvider theme={barlow}>
              <Typography fontWeight={600} lineHeight={1.10} sx={{
                fontSize: {md: 24, xs: 22},
                pl: 1
              }}>
                Safe
              </Typography>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={barlow}>
            <Typography fontSize={18} lineHeight={1.22} sx={{
              pr: {md: 5, sm: 4, xs: 0},
              mt: 2,
              height: {md: 48, sm: 66, xs: 44},
            }}>
              An educational program with a focus on safety
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item theme={breakpoints} md={4} sm={4.6} xs={12} sx={{
          borderRight: {sm: '1px solid #E1E2E3', xs: 0},
          mb: {sm: 0, xs: 5},
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            px: {md: 5, sm: 4, xs: 0},
          }}>
            <SvgIcon>
              <FunSVG />
            </SvgIcon>
            <ThemeProvider theme={barlow}>
              <Typography fontWeight={600} lineHeight={1.10} sx={{
                fontSize: {md: 24, xs: 22},
                pl: 1,
              }}>
                Fun
              </Typography>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={barlow}>
            <Typography fontSize={18} lineHeight={1.22} sx={{
              px: {md: 5, sm: 4, xs: 0},
              mt: 2,
              height: {md: 48, sm: 66, xs: 44}
            }}>
              Experience the thrill of performance driving
            </Typography>
          </ThemeProvider>
        </Grid>
        <Grid item theme={breakpoints} md={4} sm={3.9} xs={12}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            pl: {md: 5, sm: 4, xs: 0},
          }}>
            <SvgIcon>
              <SocialSVG />
            </SvgIcon>
            <ThemeProvider theme={barlow}>
              <Typography fontWeight={600} lineHeight={1.10} sx={{
                fontSize: {md: 24, xs: 22},
                pl: 1
              }}>
                Social
              </Typography>
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={barlow}>
            <Typography fontSize={18} lineHeight={1.22} sx={{
              pl: {md: 5, sm: 4, xs: 0},
              mt: 2,
              height: {md: 48, sm: 66, xs: 44},
            }}>
              Engage with other passionate drivers
            </Typography>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutUs;


