import { Box, Typography} from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';

function PrivacyHeader() {
  const faustina = createTheme({
    typography: {
      fontFamily: "Faustina",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1024,
        xl: 1440
      },
    },
  });

  const logoBreakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1024,
        xl: 1440
      },
    },
  });
  return (
    <Box theme={logoBreakpoints} sx={{
      width: '100vw',
      ml: '-50vw',
      position: 'relative',
      left: '50%',
      backgroundColor: '#F2F2F2',
      height: {md: 140, sm: 120, xs: 90}
    }}>
      <ThemeProvider theme={faustina}>
        <Typography sx={{
          color: "#2C2E31",
          textAlign: "center",
          fontSize: {md: 48, sm: 36, xs: 28},
          fontWeight: {md: 'semibold', xs: 400},
          lineHeight: {md: 1.25, sm: 1.11, xs: 1.214},
          mx: 'auto',
          pt: {sm: 4, xs: 3},
          pb: {sm: 6, xs: 4},
          height: {md: 140, sm: 49, xs: 40},
        //   width: 880
        }}
        >
            Privacy Policy
        </Typography>
      </ThemeProvider>
    </Box>
  );
}

export default PrivacyHeader;