import { Box } from '@mui/material';
// import Error from './error';
import PrivacyHeader from './privacyHeader';
import Header from '../../components/header';
import PrivacyBody from './body';

function PrivacyComponent() {
  return (
    <Box>
      <Header/>
      <PrivacyHeader/>
      <PrivacyBody />
    </Box>
  );
}

export default PrivacyComponent;