// import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Hero from '../../components/hero';
import AboutUs from '../../components/aboutUs';
import TrackDay from '../../components/trackDay';
import UpComing from '../../components/upComing';
import SignUpForm from '../../components/signUpForm';
import Questions from '../../components/questions';
// import MEDivider from '../../components/divider';


function MainComponent(){
  return (
    <Box>
      <Hero />
      {/* <AboutUs /> */}
      {/* <MEDivider /> */}
      {/* <UpComing /> */}
      {/* <TrackDay />  */}
      <SignUpForm />
      {/* <MEDivider /> */}
      {/* <Questions /> */}
    </Box>
  );
}

export default MainComponent;