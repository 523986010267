import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const url = require('../config/mailchimpUrl').url;


const CustomForm = ({ status, message, onValidated }) => {
  let email, feedback;
  const submit = () =>
    email &&
    feedback &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      FEEDBACK: feedback.value,
    });

  const Item = styled(Paper)(({theme}) => ({
    // padding: theme.spacing(1),
    textAlign: 'left',
    color: '#2C2E31',
    boxShadow: theme.shadows[0]
  }));

  const Input = styled('input')({
    fontSize: 18,
    padding: 12,
    height: 48,
    width: 400,
    border: '1px solid #2C2E31',
    '&:focus': {
      outline: 'none',
      border: '1px solid #438ABE !important'
    },
  }
  );

  const TextArea = styled('textarea')({
    width: '100%',
    height: 98,
    fontFamily: "Barlow Semi Condensed",
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    color: "#2C2E31",
    textAlign:"left",
    fontSize: 18,
    lineHeight: 1.5,
    padding: 12,
    border: '1px solid #2C2E31',
    '&:focus': {
      outline: 'none',
      border: '1px solid #438ABE'
    },
  });

  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
      color: "#2C2E31",
      textAlign:"left",
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Box>
      {status === "success" && (
        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="center"
          spacing={0}
          sx={{ 
            // mt: {md: 16.125} 
          }}
        >
          <Item
            sx={{
              // width: "300px",
              marginTop: '0%',
              // mx: 'auto'
            }}
          >
            <ThemeProvider theme={barlow}>
              <Typography 
                component={'h2'}
                sx={{
                  fontWeight: 'bold',
                  fontSize: 24,
                  textAlign: 'center'
                }}
              >
                Thank you! 
              </Typography>
            </ThemeProvider>
          </Item>
        </Stack>
      )}
      {
        status !== "success" && (
          <ThemeProvider theme={barlow}>
            <Typography color="#2C2E31" fontWeight={400} fontSize={18} lineHeight={1.5} component={'span'}>
              <Stack
                direction="column"
                alignItems="stretch"
                justifyContent="flex-start"
                spacing={3}
              >
                <Item>
                  <span className='label'>Email</span>
                  <br />
                  <Input
                    ref={node => (email = node)}
                    type="email"
                    placeholder="Enter your email"
                    sx={{
                      width: '100%',
                      // minWidth: {xs: 0, sm: 327},
                      // maxWidth: {xs: 327, md: 400}
                    }}
                    className={status === 'error' ? ' inputError' : ''}
                  />
                  {
                    status === 'error' && (
                      <Box
                        sx={{ 
                          width: 400,
                          mb: -1.25,
                        }}
                      >
                        <ThemeProvider theme={barlow}>
                          <Typography
                            sx={{
                              color: "#AE1B29", 
                              fontWeight: 400,
                              fontSize: 18, 
                              lineHeight: 1.5 
                            }} 
                            component={'span'}
                          >
                            This email is already used.
                          </Typography>
                        </ThemeProvider>
                      </Box>
                    )
                  }
                </Item>
                <Item>
                  <span className='label'> What’s important to you? <span style={{ color: '#828385'}}>(optional)</span></span>
                  <br />
                  <ThemeProvider theme={barlow}>
                    <TextArea
                      ref={node => (feedback = node)}
                      // autoFocus={true}
                      maxLength={210}
                      style={{
                        
                      }}
                      placeholder="Enter your feedback like location, pricing, timing etc."
                    />
                  </ThemeProvider>
                </Item>
                <Item>
                  <ThemeProvider theme={breakpoints}>
                    <Grid container sx={{
                      mt: 2
                    }}>
                      <Grid item md={1.8} sm={3} xs={12}>
                        <Button 
                          variant='contained'
                          sx={{  
                            background: "#18191A",
                            color: "#ffffff",
                            boxShadow: 0,
                            border: 0,
                            p: 0,
                            borderRadius: 0,  
                            '&:hover': {
                              background: '#438ABE',
                              boxShadow: 'none',
                            },
                            width: '100%',
                            maxWidth: {xs: 327, sm: 129},
                            minWidth: {xs:  0, sm: 129},
                          }}
                          onClick={submit}
                        >
                          <ThemeProvider theme={barlow}>
                            <Typography color="#ffffff" textAlign="center" fontWeight={500} fontSize={18} sx={{
                              py: 1.5,
                              px: 3,
                            }}>
                            SUBMIT
                            </Typography>
                          </ThemeProvider>
                        </Button>
                      </Grid>
                      <Grid item md={10.2} sm={9} xs={12}>
                        {
                          status === 'error' && (
                            <Box sx={{
                              fontSze: '1em',
                              mx: {xs: 0, sm: 1.5},
                              my: 2
                            }}> 
                            Please use a different email. 
                            </Box>
                          )
                        }
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Item>
              </Stack>
            </Typography>
          </ThemeProvider>
        )
      }
    </Box>
  );
};

const MailChimpForm = () => {
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
        />
      )}
    />
  );
};

export default MailChimpForm;