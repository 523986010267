import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Typography, Grid } from '@mui/material';

function TosBody(){
  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Box
      sx={{
        mb: {
          xs: 8,
          // sm: 10
        },
        mx: 'auto',
        px: {md: 4, sm: 1.5, xs: 1.375},
        // pt: {md: 3, xs: 2},
        maxWidth: {xs: 340, sm: 552, md: 880},
        background: '#FFFFFF'
      }}
      theme={breakpoints}
    >
      <ThemeProvider theme={barlow}>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Marquedevents.com TERMS AND CONDITIONS OF USE
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            MarquedEvents.com (the <b>“Website”</b>) is owned and operated by Porsche Digital, Inc. (<b>“Porsche Digital”</b>).  These Terms and Conditions of Use (<b>“Terms of Use”</b>) apply only to this website, and not to any other website, application, or other online service owned or operated by Porsche Digital or any third party website.  These Terms of Use are a legal and binding agreement between you and Porsche Digital governing your use of this website, which includes its content, information, services, and features.<br /><br />

          <b>BY DOWNLOADING OR USING THIS WEBSITE, YOU ACCEPT, WITHOUT LIMITATION OR QUALIFICATION, THE FOLLOWING TERMS OF USE. IF YOU DO NOT AGREE, PLEASE DISCONTINUE YOUR USE OF THIS WEBSITE.</b><br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
            About Marquedevents.com 
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Porsche Digital is piloting high performance drivers' education (HPDE) events with HookedonDriving LLC. Marquedevents.com communicates information about the events and collects contact information from interested participants.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Registration  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Event registration is facilitated via Eventbrite.com and your registration and the use of your personal information shall be governed by the terms of that registration site and our partner’s privacy notice.   <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Privacy  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            This Website only collects information from users who indicate an interest in receiving information regarding any future events. Please review our Privacy Notice located at <a style={{color: 'inherit'}} href="https://marquedevents.com/privacy">https://marquedevents.com/privacy</a> (the <b>“Privacy Notice”</b>), which is incorporated herein, to understand Porsche Digital’s privacy practices.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Modification of Terms of Use  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Porsche Digital reserves the right at any time to update, change, modify, or revise these Terms of Use.  Any changes will become effective upon posting to this Website, along with the date on which it was most recently updated as indicated by the <b>“Last Updated”</b> section at the end of these Terms of Use.  Your continued access to and/or use of this Website after any such changes constitutes your acceptance of the Terms of Use as modified.  It is your responsibility to review the Terms of Use regularly for updates.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Eligibility  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            You must be at least 18 years of age to access or use our Website. The Website is for personal use only. The Website may not be available in all jurisdictions, and we reserve the right to impose additional eligibility requirements.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Content; Restrictions  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            This Website and all content, information, and other materials featured, displayed, contained, and available on this Website (collectively, the "Content") are owned by or licensed to Porsche Digital and are protected by copyright, trademark, trade dress, patent, and/or other intellectual property rights.  Subject to your compliance with these Terms of Use, Porsche Digital grants you a personal, non-exclusive, non-transferable, limited right to access, use, display, and download this Website for noncommercial purposes only.  You may not, in any way, otherwise copy, reproduce, distribute, transmit, display, perform, reproduce, publish, license, modify, create derivative works from, sell, or exploit, in whole or in part, this Website or its Content.<br /><br />

            You agree to use this Website and the Content in accordance with these Terms of Use and all applicable laws and regulations.  Porsche Digital may prohibit access, use, conduct, communications, or content that we, in our sole discretion, deem to be harmful to this Website, the Content, users, us, our brand, or any other person or entity, or that violates these Terms of Use and/or applicable law.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Trademarks  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Dr. Ing. h.c. F. Porsche AG (“PAG”) is the owner of numerous trademarks, both registered and unregistered, including, without limitation, the Porsche Crest®, Porsche®, and the model numbers and names and the distinctive shapes of the Porsche automobiles, such as the federally registered 911 and Boxster automobiles which are protected by US and international trademark laws (collectively “Marks”).  You are prohibited from using any of the Marks appearing on this Website without the express prior written consent of PAG, except as permitted by applicable laws.  Other marks and logos shown on this Website may be marks owned by third parties not affiliated with Porsche and are used with permission. Nothing shown on this Website should be construed as granting, by implication, estoppel or otherwise, any permission, license or right to use any trademark, service mark or trade name displayed on this Website without the written permission of PAG or the third party owner. All text, images, and other content in this Website are protected by copyright.  No part of this Website may be reproduced in any form or by any means without prior written permission of Porsche Digital.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          No Warranties  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            THIS WEBSITE AND THE CONTENT ARE  PROVIDED  ON AN “AS IS”, AND “AS AVAILABLE” BASIS, AND  PORSCHE DIGITAL HEREBY EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.  WITHOUT LIMITING THE FOREGOING, PORSCHE DIGITAL DOES NOT GUARANTEE THAT THE WEBSITE OR CONTENT WILL BE ERROR-FREE, UNINTERRUPTED, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY DEFECTS WILL BE CORRECTED. <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Limitations of Liability  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            IN NO EVENT SHALL PORSCHE DIGITAL BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY DAMAGES (DIRECT OR INDIRECT) WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE ARISING OUT OF OR IN CONNECTION WITH THIS WEBSITE (INCLUDING ITS MODIFICATION OR TERMINATION), THE CONTENT, OR THESE TERMS OF USE, WHETHER OR NOT PORSCHE DIGITAL MAY HAVE BEEN ADVISED THAT ANY SUCH DAMAGES MIGHT OR COULD OCCUR AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.  BECAUSE SOME STATES DO NOT PERMIT THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, IN SUCH JURISDICTIONS, LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY SUCH STATE LAW.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Electronic Communications  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            When you visit this Website or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this Website. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.  You further agree that any notices provided by us electronically are deemed to be given and received on the date we transmit any such electronic communication as described in these Terms of Use.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Links  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Porsche Digital may include links from this Website to other third-party websites, applications, and social media platforms (<b>“Linked Sites”</b>).  Linked Sites are provided only for your convenience.  Please read the terms and the privacy policy applicable to any Linked Sites that you visit, as visiting any Linked Site is at your own risk.  Porsche Digital has no responsibility or liability for any Linked Sites, and does not endorse any such sites or the privacy practices, or products or services offered on any such site.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Submissions  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Except for any personally identifiable information we may collect from you under the guidelines established in our Privacy Notice, any communication or material you submit through the Website or otherwise to Porsche Digital (including feedback, data, answers, questions, comments, suggestions, ideas, plans, orders, requests or the like) (<b>“Submissions”</b>) will be treated as non-confidential and non-proprietary.  All Submissions may be used by Porsche Digital any purpose, without compensation, restrictions on use, acknowledgment of source, accountability, or liability. <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Modification/Termination of this Website  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Porsche Digital may at any time, for any reason, and without notice or liability:  (1) modify, suspend, or terminate operation of or access to this Website, or any portion thereof; (2) change, revise, or modify this Website, the Content, or any portion thereof; (3) interrupt the operation of this Website, or any portion thereof, as necessary to perform routine or non-routine maintenance, error correction, or for any other purposes; (4) impose limits on certain features and services, or restrict your access to parts of or the entire Website; and/or (5) terminate the authorization, rights, and licenses granted herein. <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Governing Law/Venue  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            This Website is governed by and subject to the laws of the State of Georgia, USA and, where applicable, U.S. federal law, without regard to conflict of law principles. You agree to submit to the exclusive jurisdiction and venue of the courts located in the County of Fulton, Georgia for any disputes arising out of these Terms of Use or the Website. Notwithstanding this provision, you agree that Porsche Digital may apply for injunctive or other equitable relief in any court of competent jurisdiction.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Dispute Resolution  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            In the event a dispute arises between you and Porsche Digital arising from or in connection with this Website, the Content, and/or these Terms of Use (a <b>“Claim”</b>), we want to provide you with an efficient, neutral, and cost-effective means of resolving the dispute.  Excluding Claims for injunctive or other equitable relief, where the total amount of the award sought is less than ten thousand U.S. Dollars ($10,000.00 USD), the party requesting relief may elect to resolve the Claim in a cost-effective manner through binding non-appearance-based arbitration.  A party electing arbitration shall initiate it through an established alternative dispute resolution (<b>“ADR”</b>) provider mutually agreed upon by the parties. The ADR provider and the parties must comply with the following rules: (1) the arbitration shall be conducted, at the option of the party seeking relief, by telephone, online, or based solely on written submissions; (2) the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and (3) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          International Use/Compliance With Laws  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            This Website is controlled, operated, and administered from offices within the United States of America.  We make no representation that this Website or the Content is appropriate or available for use at other locations outside of the United States.  You may not use or export this Website or the Content in violation of U.S. export laws and regulations.  If you access this Website from locations outside of the United States of America, you are responsible for compliance with all applicable laws and regulations.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          General Provisions  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            All or any of Porsche Digital’s rights and obligations hereunder may be assigned to a subsequent owner or operator of this Website in a merger, acquisition or sale of all or substantially all of Porsche Digital’s assets.  If, for any reason, a court of competent jurisdiction finally determines any provision of these Terms of Use, our Privacy Notice, or any portion thereof to be unenforceable, such provision shall be enforced to the maximum extent permissible so as to give the intended effect thereof, and the remainder of these Terms of Use and Privacy Notice shall continue in full force and effect.  Porsche Digital’s failure to act with respect to a breach by you or others does not waive Porsche Digital’s right to act with respect to that breach or subsequent or similar breaches. No consent or waiver by Porsche Digital hereof shall be deemed effective unless delivered in a writing signed by a duly appointed officer of Porsche Digital.   These Terms of Use, together with our Privacy Notice, constitute the entire agreement between Porsche Digital and you with respect to your use of this Website and the Content and supersede all previous written or oral agreements between Porsche Digital and you with respect to such subject matter.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Notices  
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            All questions and notices relating to this Website and these Terms of Use should be sent as follows: <br /><br />

          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: 4
          }}>
            <Grid item theme={breakpoints} xs={12} sx={{
            }}>
            Porsche Digital, Inc.
            </Grid>
            <Grid item theme={breakpoints} xs={12} sx={{
            }}>
            Attn:  Legal Department
            </Grid>
            <Grid item theme={breakpoints} xs={12} sx={{
            }}>
            One Porsche Drive
            </Grid>
            <Grid item theme={breakpoints} xs={12} sx={{
            }}>
            Atlanta, GA 30354 
            </Grid>
            <Grid item theme={breakpoints} xs={12} sx={{
            }}>
            Email: <a style={{color: 'inherit'}} href="mailto: privacy@porsche.com">privacy@porsche.com</a> 
            </Grid>
          </Grid>
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Notice for California Users
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Under California Civil Code Section 1789.3, California website users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite N 112, Sacramento, California 95834, or by telephone at (800) 952-5210.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Last Updated
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Terms of Use last updated February 2022.<br /><br />

            Copyright © 2022 Porsche Digital, Inc.   All rights reserved.<br /><br />
        </Typography>
      </ThemeProvider>
    </Box>
  );
}

export default TosBody;