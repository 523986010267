import Box from '@mui/material/Box';
import { createTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import Images from '../../assets/images';
import Header from '../../components/header';

function EditorialHero(){
  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1024,
        xl: 1440
      },
    },
  });

  return (
    <Container
      sx={{
        mb: {
          xs: 0,
          // sm: 10
        },
        mx: 'auto',
        px: '0 !important',
        // maxWidth: {xs: 455, sm: 648, md: 880, lg: 1024, xl: 1200}
      }}
      themes={breakpoints}
    >
      <Header />
      <Box
        sx={{
          position: 'relative',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: Images.editorialHero,
          height: {xs: 220, sm: 357, md: 400},
          m: 'auto',
          maxWidth: {xs: 455, sm: 648, lg: 1024, xl: 1200}
        }}
        theme={breakpoints}
      />
    </Container>
  );
}

export default EditorialHero;