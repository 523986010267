import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Typography, Grid } from '@mui/material';

function PrivacyBody(){
  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Box
      sx={{
        mb: {
          xs: 8,
          // sm: 10
        },
        mx: 'auto',
        px: {md: 4, sm: 1.5, xs: 1.375},
        // pt: {md: 3, xs: 2},
        maxWidth: {xs: 340, sm: 552, md: 880},
        background: '#FFFFFF'
      }}
      theme={breakpoints}
    >
      <ThemeProvider theme={barlow}>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
          <br /><br /> This privacy notice (“Privacy Notice”) describes the information that Porsche Digital, Inc. (“Porsche Digital”) collects about you, how we use and share your information, and your choices.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Scope
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            This Privacy Notice applies to information collected about you by or on behalf of Porsche Digital as set forth in the below “Information We Collect” section, including through the websites, mobile applications, online services, in person interview, surveys, registrations during events or programs where this Privacy Notice is posted or linked (our “Online Services”). Unless otherwise indicated, all references to “Porsche Digital,” “we” or “our” in this Privacy Notice means Porsche Digital. This Privacy Notice does not apply to any of our affiliates, other Porsche companies, or Porsche dealers which are independent third parties. Our Online Services are for users located exclusively in the United States of America. By using our Online Services, you consent to the use and disclosure of your information in accordance with this Privacy Notice and subject to the applicable state and federal laws of the United States.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
            Links, Third-Party Websites, and Social Media Platforms
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Our Online Services may contain links to the websites of other Porsche companies, Porsche dealers, or other third parties, as well as to social media platforms such as Facebook or Twitter (“Linked Sites”). Porsche Digital is not responsible and has no liability for the privacy policies or practices of Linked Sites. We recommend that you review the privacy policies of each Linked Site you visit.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
            Information We Collect
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            As used in this Privacy Notice, “Personal Information” means information that identifies or can be linked to or associated with a specific individual, such as name, e-mail address, phone number, driver’s license number, Porsche ID or credit card number. “Information” is collectively used herein to refer to both Personal Information and other information that does not identify a specific individual.<br /><br />

            Porsche Digital and its third-party service providers collect Information about you from a variety of sources, including Personal Information you provide directly to us and Information we collect about you when you access and use our Online Services. We also collect your Personal Information from independent Porsche dealers. We may combine any of the Information collected about you for the purposes described in this Privacy Notice.<br /><br />

            Information You Provide Us. We collect Personal Information when you:<br /><br />
          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            register for or use our Online Services;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            register for and/or attend any Porsche Digital event or program or web specials, and/or participate in any event, program, contest, surveys, or other promotion that we may offer from time to time (collectively, “Events”);
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            3.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            subscribe to our newsletter, or other communications from us;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            4.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            download a software update for our mobile application;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            5.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            contact or communicate with us, including requesting information from us;
            </Grid>

            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            6.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            access or use Porsche Digital pages or accounts on any third-party social media platforms such as Facebook or Twitter (“Social Media Pages”);
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            7.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            provide information to us in connection with transactions or potential transactions;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            8.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            provide or submit comments, suggestions, experiences, or other content to us; and/or
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            9.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            otherwise interact with us and our Online Services.
            </Grid>
          </Grid>
          <br /><br />

          Information Collected Automatically.<br /><br />

          Cookies: Cookies are small text files stored by your browser that allow us to learn more information about your visit or use of our Online Services. We may use cookies to remember your Personal Information, including your registration information, and to track your usage of our Online Services over time. Most browsers automatically accept cookies. You have the choice to set your browser to accept or reject cookies. Each browser is different, so check the "Help" menu of your browser to learn how to change your cookie preferences.<br /><br />

          Analytics Information: We may use third-party analytics tools, such as Google Analytics, to help us measure traffic and usage trends for our Online Services. These tools collect information sent by your browser or mobile device, including the pages you visit and other information that assists us in improving our Online Services. We collect and use this analytics information in aggregate form.<br /><br />

          Log File Information: Log file information is automatically reported by your browser or mobile device each time you access our Online Services. When you use our Online Services, servers may automatically record certain log file information such as your web request, Internet Protocol (IP) address, browser type, referring / exit pages and URLs, number of clicks and how you interact with Linked Sites, domain names, landing pages, pages viewed, and other such information.<br /><br />

          Clear Gifs/Web Beacons: When you use our Online Services, we may employ clear gifs (also known as web beacons) which are used to anonymously track your online usage patterns. In addition, we may also use clear gifs in HTML-based emails sent to our users to track which emails are opened and which links are clicked by recipients. This information facilitates more accurate reporting and improvement of our Online Services.<br /><br />

          Location Data: When you access our Online Services via a website or mobile device, we may access, collect, monitor, and/or remotely store information regarding your computer or device, as well as real-time “location data,” which may include GPS coordinates. Location data may convey to us information about how you browse and use our Online Services. You may opt-out from allowing us to have access to your location data by adjusting the permissions in your mobile device, though some features of our Online Services may not function properly if use or availability of location data is impaired or disabled.<br /><br />

          <b>Information We Receive From Third Parties.</b>
          <br /><br />

          We may receive Information about you from third parties. For example, if you access our Online Services through a third-party connection or log-in (e.g., through Facebook), such third party may pass certain information about your use of its service to us. We may also receive Information you have provided to Porsche dealers, our affiliates, or Porsche clubs. We may also obtain other Information about you such as change of address, contact information, or demographic information from commercially or publicly available sources.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          How We Use Your Information
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
          We use your Information, including Personal Information, for a variety of business purposes, including:<br /><br />
          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            improving and customizing our Online Services;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            providing our products and services to you;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            3.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            communicating with you regarding Porsche Digital and its products, services, and Events;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            4.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            customizing your Porsche experience;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            5.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            creating and managing your online Porsche Login registration;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            6.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            responding to your inquiries, comments, or postings;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            7.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            informing you about the latest products of Porsche Digital;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            8.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            internal operations such as enhancing our products and services, marketing efforts, and to conduct research and analysis;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            9.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            investigating or settling inquires or disputes;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            10.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            marketing purposes;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            11.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            enforcing our Terms and Conditions of Use; and/or
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            12.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            protecting the security and integrity of our business and our Online Services.
            </Grid>
          </Grid>
          <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Your Choices In How We Use Your Information
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
          While Porsche Digital may contact you with offers and information concerning Porsche Digital or its products, services or Events, you always have the choice not to receive such items. You may contact us as described in the below Contact Us section to communicate your marketing preferences and request to be removed from marketing lists. You may also request that your Personal Information not be shared with independent Porsche dealerships as part of related marketing and customer care programs (although you should be aware that these dealerships also collect personal data directly from you).<br /><br />

          In addition, our Online Services allow you to communicate your marketing preferences as follows (or as otherwise specifically indicated on our Online Services):<br /><br />
          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            At the time of registering as a Porsche Online Services user, you will be given a choice whether to receive promotional e-mails from Porsche. Each promotional e-mail you receive from us will provide you a specific opt-out mechanism to allow you to unsubscribe from receipt of these promotional e-mails.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            By accessing your profile under “Porsche Login” on the Online Services, you may also update your Personal Information in our database or change your marketing preferences.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            3.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Whether or not registered as a Porsche Online Services user, users who subscribe to the Porsche Magazine or Newsletter may unsubscribe by following the link provided therein, by calling 1-800-PORSCHE or by updating their preferences on our Online Services.
            </Grid>
          </Grid>
          <br /><br />

          You may contact us as described in the below Contact Us section with any questions or concerns you may have about our privacy practices, including requests to update or correct your Personal Information. Even if you choose not to receive promotional e-mails or mailings from Porsche, we may continue to use your Personal Information in order to contact you in connection with product recalls or other transactional purposes.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          How We Share Your Information
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
          We share and disclose your Information as follows:<br /><br />
          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            with third parties that provide a variety of services on our behalf, including the development, maintenance, and support of our Online Services, payment processing, communications, and marketing research and analysis;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            with independent, authorized Porsche dealers for customer care and marketing efforts;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            3.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            with members of our affiliated group of companies for promotional or other purposes;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            4.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            to manage and administer Events;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            5.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            to comply with law, a court order, or other legal process, or in connection with a legal investigation;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            6.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            as may be necessary to protect the rights and interests of Porsche, its affiliates, and authorized Porsche dealers, and our and their respective employees, customers, visitors, users, or any other person or entity;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            7.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            to enforce any applicable terms and conditions and other agreements;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            8.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            in the event of a corporate transaction, such as a merger, reorganization, or acquisition, in which event your Personal Information may be transferred to the acquiring company;
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            9.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            in aggregated or other non-personally identifiable form; and/or
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            10.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            in accordance with your consent.
            </Grid>
          </Grid>
          <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
            Google Analytics
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} ,
            wordWrap: 'break-word'
          }}
        >
            We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”), on certain of our Online Services. Google Analytics uses cookies to collect information such as how often you visit our Online Services, what pages you visit within our Online Services, and what other sites you visited prior to coming to our Online Services. We use the information provided by Google Analytics to improve our Online Services. For more information regarding how Google collects, uses, and shares your information please visit <a style={{color: 'inherit'}} href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of our Online Services. By using our Online Services, you consent to the processing of data about you by Google as specified herein. To provide users more choice on how their data is collected by Google Analytics, Google developed the Opt-out Browser Add-on: <a style={{color: 'inherit'}} href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a>. By installing this Add-on, no information is being sent to Google Analytics.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
            California’s Do Not Track Notice
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            At this time, there is no worldwide uniform or consistent industry standard or definition for responding to, processing, or communicating Do Not Track signals. Thus, our Online Services are unable to respond to Do Not Track Signals.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
            Additional Notice for California Consumers
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18} 
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
          This part of our Privacy Notice applies to consumers who reside in the State of California.<br /><br />

          <b>Personal Information We Collect about California Consumers.</b><br /><br />

          We describe the Personal Information we have collected from consumers in the twelve (12) months preceding the effective date of this Privacy Notice in the part of our Privacy Notice titled, Information We Collect. The information we collect includes the following:<br /><br />

          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Identifiers such as name, e-mail address, phone number, and driver’s license number, Porsche ID, postal address, and Internet Protocol address.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            The following categories of Personal Information described in California Civil Code § 1798.80(e): (1) the Personal Information listed in the preceding bullet point as “identifiers,” (2) payment information such as credit card information, (3) information that you provide in connection with transactions or potential transactions, (4) information you provide or that we record when you contact or communicate with us, and (5) the other information that identifies, relates to, describes, or is capable of being associated with, a particular individual that we describe in Information We Collect above.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            3.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Characteristics of protected classifications under California or federal law, such as language preference.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            4.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Commercial information, including information about interest you have expressed in any of our products or services; registration information for any Porsche event or program, including Porsche driving experiences and the Porsche Travel Club, or web specials; and information you provide or submit to us when you participate in any event, program, contest, surveys, or other promotion that we may offer from time to time.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            5.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding your interaction with our Online Services, advertisements that we display on our websites or mobile apps or on third-party sites and apps, or Porsche pages or accounts on any third-party social media platforms such as Facebook or Twitter.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            6.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Geolocation data relating to your computer or device when you interact with our Online Services that post this Privacy Notice.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            7.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Audio information from calls placed with customer service and accounting centers which may be recorded, and electronic information in the form of Internet or other electronic network activity information as described above.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            8.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Inferences drawn from (1) the information we collect when you visit our websites, use our apps, or our social media apps, tools, widgets and plug-ins, (2) information we collect, including through third-party suppliers, regarding content and other data posted on the Internet (such as public locations on the Internet), and (3) information about consumer preferences and behavior that we collect on our Online Services or purchase from third parties in order to create a profile about a consumer reflecting the consumer’s preferences, characteristics, predispositions, or behavior.
            </Grid>
          </Grid>
          <br /><br />

          We collect this Personal Information from sources that include you when you access and use our Online Services, third-party analytics providers such as Google Analytics, third parties such as Facebook if you access our Online Services through such third parties, and providers of publicly available information. For more information, please see Information We Collect and Information Collected Automatically.<br /><br />

          For information regarding the purposes for which we collect and use the Personal Information we collect, please see How We Use Your Information.<br /><br />

          Disclosures of Personal Information for Monetary or Other Valuable Consideration or for Business Purposes<br /><br />

          We have disclosed the following categories of Personal Information to other businesses or third parties for monetary or other valuable consideration within the twelve (12) months preceding the effective date of this Privacy Notice:<br /><br />

          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Identifiers and Commercial Information. Name, address and phone number. We share this information with independent Porsche dealers for marketing purposes.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Identifiers and Inferences. Pseudonymous identifiers for individuals who visit our Web sites and mobile apps and the devices such individuals use to visit our Web sites and mobile apps, data relating to usage of our Web sites and apps and inferences drawn from such usage data, and data acquired from third-party marketing and digital advertising providers. We share this information with third-party vendors that provide consumer behavioral analytics and digital advertising service to Porsche, and where applicable these vendors may use the information for use for their own purposes.
            </Grid>
          </Grid>
          <br /><br />

          We have disclosed Personal Information in all or substantially all of the categories identified in this Additional Notice for California Consumers for various business purposes. For more information about the categories of Personal Information we have disclosed, the categories of entities with which we have disclosed this information and the purposes for which we have disclosed the information, please see How We Share Your Information.<br /><br />

          We do not disclose personal information of individuals we know to be under the age of 16 to other businesses or third parties for consideration.<br /><br />

          <b>Your California Privacy Rights</b><br /><br />

          If you are a California consumer, you have the following rights. We will honor requests received to the extent required by applicable law and within the time provided by law.<br /><br />

          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Right to Access and Information regarding Personal Information. You have the right to request that we disclose to you the categories and specific pieces of Personal Information we have collected about you. Specifically, you have the right to request that we disclose the following to you, in each case in the twelve-month period preceding your request:
              <ul type="circle">
                <li>
              the categories of Personal Information we have collected about you;
                </li>
                <li>
              the categories of sources from which the Personal Information is collected;
                </li>
                <li>
              our business or commercial purpose for collecting or selling Personal Information;
                </li>
                <li>
              the categories of third parties with whom we share Personal Information;
                </li>
                <li>
              the specific pieces of information we have collected about you;
                </li>
                <li>
              the categories of Personal Information about you, if any, that we have disclosed for monetary or other valuable consideration and the categories of third parties to which we have disclosed the information, by category or categories of Personal Information for each third party to which we disclosed the Personal Information; and
                </li>
                <li>
              the categories of Personal Information about you that we disclosed for a business purpose.
                </li>
              </ul>
            </Grid>
          </Grid>
          <br /><br />

          We will deliver Personal Information that we are required by law to disclose to you in the manner required by law within 45 days after receipt of a verifiable request, unless we notify you that we require additional time to respond, in which case we will respond within such additional period of time required by law. We may deliver the Personal Information to you through your account, if you maintain an account with Porsche, or, if not, electronically or by mail at your option. If electronically, then we will deliver the information in a portable and, to the extent technically feasible, in a readily useable format that allows you to transmit the information from one entity to another without hindrance.<br /><br />

          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Right to Deletion of Personal Information. You have the right to request that we delete Personal Information about you that we have collected from you.
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Right to Opt Out. You have the right to opt out of the disclosure of Personal Information about you to third parties for monetary or other valuable consideration. To opt-out, click <a style={{color: 'inherit'}} href="https://www.porsche.com/usa/privacy-policy/contact/">Do Not Sell My Personal Information</a>. To opt-out from the sale of personal information for valuable consideration to analytics, social media, and advertising partners, please use the following links:
              <ul type="circle" style={{ wordWrap: 'break-word' }}>
                <li>
                  <a style={{color: 'inherit'}} href="https://optout.aboutads.info/">https://optout.aboutads.info/</a>
                </li>
                <li>
                  <a style={{color: 'inherit'}} href="https://optout.networkadvertising.org">https://optout.networkadvertising.org</a>
                </li>
                <li>
                Google Advertising opt-out: <a style={{color: 'inherit'}} href="https://support.google.com/ads/answer/7395996">https://support.google.com/ads/answer/7395996</a>
                </li>
                <li>
                Google Analytics opt-out: <a style={{color: 'inherit'}} href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
                </li>
                <li>
                Trade Desk opt-out: <a style={{color: 'inherit'}} href="https://www.adsrvr.org/">https://www.adsrvr.org/</a>
                </li>
                <li>
                Verizon Media opt-out:<br />
                  <a style={{color: 'inherit'}} href="https://www.verizonmedia.com/policies/us/en/verizonmedia/privacy/controls/index.html">https://www.verizonmedia.com/policies/us/en/verizonmedia/privacy/controls/index.html</a>
                </li>
              </ul>
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            3.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Right to Non-Discrimination. We may not discriminate against you because of your exercise of any of the foregoing rights, or any other rights under the California Consumer Privacy Act, including by:
              <ul type="circle">
                <li>
                Denying you goods or services;
                </li>
                <li>
                Charging different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;
                </li>
                <li>
                Providing you a different level or quality of goods or services; or
                </li>
                <li>
                Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.
                </li>
              </ul>
            </Grid>
          </Grid>
          <br /><br />

          We may, however, charge different prices or rates, or provide a different level or quality of goods or services, if that difference is reasonably related to the value provided to Porsche by your Personal Information.<br /><br />

          <b>Requests to Exercise Your Rights</b><br /><br />

          You may request to exercise the foregoing rights by:<br /><br />

          <Grid container theme={breakpoints} sx={{
          // mt: {md: 10},
            display: 'flex',
            justifyContent: 'center',
            pl: {md: 2, xs: 1}
          }}>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            1.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Submitting a request through <a style={{color: 'inherit'}} href="https://porsche.com/usa/privacy-policy/contact/">https://porsche.com/usa/privacy-policy/contact/</a>; or
            </Grid>
            <Grid item theme={breakpoints} md={.5} xs={1.5} sx={{
              verticalAlign: 'top',
              textAlign: 'center',
              pr: 1
            }}>
            2.
            </Grid>
            <Grid item theme={breakpoints} md={11.5} xs={10.5} sx={{
            }}>
            Calling us toll-free at 1-800-PORSCHE.
            </Grid>
          </Grid>
          <br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Agent Authorization
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Under California law, you may designate an authorized agent to make a request on your behalf. You may make such a designation by providing the agent with written permission to act on your behalf. Even if you use an agent, as permitted by law, we may require verification of the agent’s authorization to act on your behalf, require you to confirm you have authorized the agent to act on your behalf, and/or require you to verify your own identity.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Verification
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            We may require you to provide certain information, including contact information, to verify your identity before acting on your request to exercise your rights or granting you access to your information. We will only use this information to confirm your identity. In some cases we may need to collect additional information from you, and depending on the type of request we may use a third-party identity verification service to verify your identity. If we use a third-party identity verification service, our third-party identification verification service may ask you certain identity verification questions to match the information you provide with information that it has in its database.<br /><br />

          <b>Data Sharing for Direct Marketing Purposes</b><br /><br />

            California Civil Code Section § 1798.83 further permits California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. If you are a California resident, you may ask us to refrain from sharing your Personal Information with certain of our affiliates and other third parties for their marketing purposes. Please tell us your preference by contacting us as specified below. To make such a request, please tell us your preference by contacting us as specified below.<br /><br />

          <b>Accessibility</b><br /><br />

            If you use assistive technology and the format of this Privacy Notice interferes with your ability to access information, please contact us at privacy@porsche.us.<br /><br />

          <b>How We Protect Your Information</b><br /><br />

            We implement reasonable technical, administrative, and physical safeguards designed to keep your Personal Information secure. For example, when you submit sensitive Personal Information, such as credit card information, through our Online Services, we utilize secure sockets layer (SSL) encryption technology. We also request that our third-party service providers use appropriate security measures to protect your information from unauthorized access or use. However, no data transmission is guaranteed as 100% secure.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Submissions and User Generated Content
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Unless governed by separate terms identified at the time of submission, all suggestions, materials, remarks, or other intellectual property (e.g., ideas, experiences, and photos) you communicate or submit to us (“Submissions”) will be considered non-confidential and non-proprietary. Porsche Digital shall have no obligation of any kind with respect to such Submissions and shall be free to use and distribute Submissions for any purposes, without limitation of any kind.<br /><br />

            Our Online Services and Social Media Pages allow you to post messages and submit content (e.g., comments, phots, blogs, postings, etc.), which may include your Personal Information. You are responsible for all actions resulting from any information, including Personal Information, which you post on our Online Services or Social Media Pages. Please remember that any information you post becomes public information, is not subject to this Privacy Notice, and Porsche is not responsible for the results of such postings.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Email Security
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            “Phishing” is a scam designed to steal your Personal Information. If you receive an e-mail that looks like it is from us asking you for your Personal Information, please notify us as provided below in the Contact Us section.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Children’s Privacy
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Our Online Services are not intended for use by children under 13. We do not knowingly collect Personal Information from children under the age of 13 without parental or guardian consent. Please contact us via the Contact Information provided below if you believe we may have collected such information without parental or guardian consent or other than in a manner authorized by law, and we will work to delete it.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Contact Us
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            If you have any questions or comments about this Privacy Notice or our privacy practices or any requests with respect to the Personal Information we collect about you, you may contact us at 1-800-PORSCHE, by sending an e-mail to <a style={{color: 'inherit'}} href="mailto: privacy@porsche.com">privacy@porsche.com</a>, or by mail to Porsche Digital, Inc., One Porsche Drive, Atlanta, Georgia 30354, Attn: Privacy/Legal Department.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Your Acceptance of Our Privacy Notice
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            By using our Online Services, you signify your acceptance of this Privacy Notice, and you agree to adhere to the terms and conditions posted on our Online Services under “Legal notice.” If you do not agree with the terms of this Privacy Notice, please do not access or use our Online Services or submit any Personal Information to us.<br /><br />
        </Typography>
        <Typography 
          sx={{
            color: "#2C2E31",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {md: 24, xs: 22},
            lineHeight: {sm: 1.27, xs: 1.1},
            mt: {sm: 6, xs: 5},
            mb: 2
          }} 
        >
          Changes To This Privacy Notice
        </Typography>
        <Typography 
          color="#2C2E31" 
          textAlign="left" 
          fontWeight={400} 
          fontSize={18}
          sx={{
            lineHeight: {md: 1.33, xs: 1.22} 
          }}
        >
            Porsche reserves the right to modify this Privacy Notice at any time, so you should review it frequently for changes. If we make changes to this Privacy Notice, we will post such changes here at <a style={{color: 'inherit'}} href="https://marquedevents.com/privacy">https://marquedevents.com/privacy</a>. Your continued use of our Online Services and/or provision of your Personal Information after such posting constitute your acceptance of such changes. Please contact us as described in the Contact Us section if you would like information regarding any changes.<br /><br />

            Effective Date: July 1, 2021<br /><br />

            Copyright © 2022 Porsche Digital, Inc.<br /><br />
        </Typography>
      </ThemeProvider>
    </Box>
  );
}

export default PrivacyBody;