const images = {
  editorialHero: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_hero.png)',
  editorialMarqued: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_marqued_desktop.png)',
  editorialBMW: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_bmw.png)',
  editorialMercedes: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_mercedes.png)',
  editorialPirelli: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_pirelli.png)',
  editorialTicket: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_ticket.png)',
  editorialThumbnail: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_thumbnail_desktop.png)',
  editorialThumbnailNew: 'url(https://dmgyu1fxfz7jp.cloudfront.net/editorial_thumbnail_desktop_new.png)',
  emailSignup: 'url(https://dmgyu1fxfz7jp.cloudfront.net/email_signup_desktop.png)',
  eventThumbnail: 'url(https://dmgyu1fxfz7jp.cloudfront.net/event_thumbnail_desktop.png)',
  heroDesktop: 'url(https://dmgyu1fxfz7jp.cloudfront.net/hero_desktop.png)',
  heroDesktopNew: 'url(https://dmgyu1fxfz7jp.cloudfront.net/hero_desktop_new.png)',
  logo: 'url(https://dmgyu1fxfz7jp.cloudfront.net/marquedevents_logo.svg)',
};

export default images;