import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function Error() {

  const barlow = createTheme({
    typography: {
      fontFamily: "Barlow Semi Condensed",
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const breakpoints = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1001,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <Box sx={{
      width: '100vw',
      ml: '-50vw',
      position: 'relative',
      left: '50%',
      backgroundColor: '#F7F7F7',
    }}>
      <Container alignItems='center' theme={breakpoints} 
        sx={{
          height: '100vh',
        }}>
        <Box 
          sx={{
            height: {md: 80, sm: 96, xs: 64}
          }}
        />
        <Stack alignItems='center' theme={breakpoints}
          sx={{
            maxWidth: {md: 600, sm: 520, xs: 343},
            height: {md: 286, sm: 282, xs: 244},
            // boxShadow: '0px 8px 32px rgb(0 0 0 / 20%)',
            mx: 'auto',
            background: '#FFFFFF',
          }}
        >
          <ThemeProvider theme={barlow}>
            <Typography 
              sx ={{
                color: "#2C2E31",
                fontWeight: 'bold',
                fontSize: {md: 24, xs: 22},
                lineHeight: 1.25,
                textAlign: "center",
                mt: {sm: 8, xs: 5},
              }}
            >
            Looks like we took a wrong turn!
            </Typography>
            <Typography variant="body1" 
              sx ={{
                color: "#2C2E31",
                fontSize: 18,
                lineHeight: 1.33,
                textAlign: "center",
                mt: 2,
                mb:5
              }}
            >
            Either something went wrong, or this page doesn’t exist.
            </Typography>
            <Button 
              variant="outlined"
              sx={{  
                boxShadow: 0,
                border: '1px solid #2C2E31',
                p: 0,
                height: 48,
                width: {xs: '100%'},
                maxWidth: 156,
                borderRadius: 0,  
                '&:hover': {
                  border: '1px solid #438ABE',
                  backgroundColor: '#00000000',
                  boxShadow: 'none',
                },
                'a': {
                  textDecoration: 'inherit'
                }
              }}
              href='/'
            >
              <Typography color="#2C2E31" textAlign="center" component='span' fontWeight={500} fontSize={18} sx={{
                py: 1.5,
                px: 3,
                '&:hover': {
                  color: '#438ABE'
                },
                'a': {
                  textDecoration: 'inherit'
                }
              }}>
                GO BACK HOME
              </Typography>
            </Button>
          </ThemeProvider>
        </Stack>
      </Container>
    </Box>
  );
}

export default Error;