import * as React from 'react';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainComponent from './main';
import TosComponent from './tos';
import PrivacyComponent from './privacy';
import EditorialComponent from './editorial';
import Footer from '../components/footer';
import NotFound from './notFound';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import ReactGA from "react-ga4";
import ScrollToTop from '../helpers/scrollToTop';
ReactGA.initialize("G-PD6271L5N7");

export default function App() {  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" disableGutters>
        <ScrollToTop>
          <Routes>
            <Route path="/">
              <Route index element={<Main />} />
              <Route path="tos" element={<Tos/>} />
              <Route path="editorial" element={<Editorial/>} />
              <Route path="privacy" element={<Privacy/>} />
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </ScrollToTop>
        <Footer />
      </Container>
    </React.Fragment>
  );
}

function Main() {
  return (
    <MainComponent />
  );
}

function Tos() {
  return (
    <TosComponent />
  );
}

function Editorial() {
  return (
    <EditorialComponent />
  );
}

function Privacy(){
  return (
    <PrivacyComponent />
  );
}

function NoMatch() {
  return (
    <NotFound />
  );
}
